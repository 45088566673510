import { useNavigate } from "react-router-dom";
const useNavigateViewProduct = () => {
  const navigate = useNavigate();
  const navigateViewProduct = (productDetails, slugData) => {
    if (productDetails) {
      navigate(`/${slugData?.page_slug}`, {
        state: {
          page_id: slugData?.page_id,
          productid: parseInt(productDetails?.product_id),
        },
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return { navigateViewProduct };
};
export default useNavigateViewProduct;
