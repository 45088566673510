import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../../Shared/baseUrl";

export const fetchSettingsData = createAsyncThunk(
  "settings/fetchSettingsData",
  async () => {
    try {
      const response = await apiServices.get("/settings-data");
      return response.data?.data;
    } catch (error) {}
  }
);

const initialState = {
  settingsData: [],
  settingsLoading: false,
  settingsError: "",
};
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsData.pending, (state) => {
        state.settingsLoading = true;
      })
      .addCase(fetchSettingsData.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settingsData = action.payload;
      })
      .addCase(fetchSettingsData.rejected, (state) => {
        state.settingsLoading = false;
        state.settingsError = "";
      });
  },
});
export default settingsSlice.reducer;
