import React, { useEffect } from "react";
import "./App.css";
import AllRoutes from "./Routes/AppRoutes";
import { Toaster } from "react-hot-toast";
import useReducerHook from "./Hooks/useReducerHook";
import useSettingsData from "./Hooks/useSettingsData";
import { APP_URL } from "./Shared/baseUrl";
import useSecurity from "./Security/Security";

const App = () => {
  useSecurity();
  const { settingsData } = useReducerHook();
  const siteName = useSettingsData(settingsData?.settingsData, "SITE_NAME");
  const sitelogo = useSettingsData(settingsData?.settingsData, "SITE_LOGO");
  const seoContent = useSettingsData(settingsData?.settingsData, "SEO_TEXT");

  useEffect(() => {
    if (siteName) {
      document.title = siteName;
    }
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    const faviconUrl = `${APP_URL}${sitelogo}`;
    if (link.href !== faviconUrl) {
      link.href = faviconUrl;
    }
    let seoMeta = document.querySelector("meta[name='description']");
    if (!seoMeta) {
      seoMeta = document.createElement("meta");
      seoMeta.name = "description";
      document.head.appendChild(seoMeta);
    }
    if (seoMeta.content !== seoContent) {
      seoMeta.content = seoContent;
    }
  }, [siteName, sitelogo, seoContent]);

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          // duration: 5000,
          style: {},
        }}
      />
      <AllRoutes />
    </>
  );
};

export default App;
