import { createSlice } from "@reduxjs/toolkit";
import { TAB_1 } from "../../Shared/tabs";

const initialState = {
  menuId: TAB_1,
};

const profileMenuSlice = createSlice({
  name: "productsearch",
  initialState,
  reducers: {
    setProfileMenuID: (state, action) => {
      state.menuId = action.payload;
    },
    
  },
});

export const { setProfileMenuID } = profileMenuSlice.actions;


export default profileMenuSlice.reducer;