import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../../Shared/baseUrl";

export const fetchMainDetails = createAsyncThunk(
  "catgory/fetchMainDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.get("/dropdown-main-categories");
      return response.data.data;
    } catch (error) {}
  }
);
export const fetchSubDetails = createAsyncThunk(
  "catgory/fetchSubDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.get("/dropdown-sub-categories");
      return response.data.data;
    } catch (error) {}
  }
);

const initialState = {
  mainCatgory: [],
  mainError: "",
  mainLoading: false,
  subCatgory: [],
  subError: "",
  subLoading: false,
};

const mainCatgory = createSlice({
  name: "mainCatgorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMainDetails.pending, (state) => {
        state.mainLoading = true;
      })
      .addCase(fetchMainDetails.fulfilled, (state, action) => {
        state.mainLoading = false;
        state.mainCatgory = action.payload;
      })
      .addCase(fetchMainDetails.rejected, (state) => {
        state.mainLoading = false;
        state.mainError = "redux error";
      })
      .addCase(fetchSubDetails.pending, (state) => {
        state.subLoading = true;
      })
      .addCase(fetchSubDetails.fulfilled, (state, action) => {
        state.subLoading = false;
        state.subCatgory = action.payload;
      })
      .addCase(fetchSubDetails.rejected, (state) => {
        state.subLoading = false;
        state.subError = "redux error";
      });
  },
});

export default mainCatgory.reducer;
