import React from "react";
import "./test.css"
const Test = () => {
  return (
    <>
      <div class="header-design">
        <div class="red-section">
          <div class="red-small"></div>
          <div class="red-medium"></div>
          <div class="red-large"></div>
        </div>
        <div class="black-section"></div>
      </div>

      <div class="header-design-bottom">
        <div class="black-section-bottom"></div>

        <div class="red-section-bottom">
          <div class="red-small-bottom"></div>
          <div class="red-medium-bottom"></div>
          <div class="red-large-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default Test;
