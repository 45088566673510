import axios from "axios";
import useSendToken from "../../Hooks/useSendToken";
import { BASE_URL } from "../../Shared/baseUrl";
const apiService = axios.create({
  baseURL: BASE_URL,
});
export const UpdatecartData = async (token, payload) => {
  try {
    const apiCall = useSendToken(token);
    const response = await apiCall.post("/update-to-cart", payload);
    return response.data;
  } catch (error) {}
};
export const removeCartData = async (token, payload) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post(
      "/remove-add-to-cart-product",
      payload,
      config
    );
    return response.data;
  } catch (error) {}
};
