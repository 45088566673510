import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryId: 0,
  brandId: 0,
  minPrice: 0,
  maxPrice: 0,
  totalPrice: {
    min: 0,
    max: 0,
  },
  searchData: "",
  sortData: "asc",
  numberofPage: 20,
  pageNumber: 0,
  gridView: "1",
  modalOpen:false,
  isQuickView:false
};

export const productFilterSlice = createSlice({
  name: "FilterProduct",
  initialState,
  reducers: {
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    emtyCatgoryId: (state) => {
      state.categoryId = 0;
    },
    setBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    emtyBrandId: (state) => {
      state.brandId = 0;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setTotalMinPrice: (state, action) => {
      state.totalPrice.min = action.payload;
    },
    setTotalMaxPrice: (state, action) => {
      state.totalPrice.max = action.payload;
    },
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setSortData: (state, action) => {
      state.sortData = action.payload;
    },
    setNumber0fPage: (state, action) => {
      state.numberofPage = action.payload;
    },
    setNumberofPageItem: (state, action) => {
      state.pageNumber = action.payload;
    },
    setGridView: (state, action) => {
      state.gridView = action.payload;
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    setIsQuickViewOpen :(state, action)=>{
      state.isQuickView = action.payload
    }

  },
});
export const {
  setBrandId,
  setCategoryId,
  emtyBrandId,
  emtyCatgoryId,
  setMinPrice,
  setMaxPrice,
  setTotalMinPrice,
  setTotalMaxPrice,
  setSearchData,
  setSortData,
  setNumber0fPage,
  setNumberofPageItem,
  setGridView,
  setModalOpen,
  setIsQuickViewOpen
} = productFilterSlice.actions;
export default productFilterSlice.reducer;
