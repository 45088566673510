import { useMemo } from "react";

const useSettingsData = (data, key) => {
  const result = useMemo(() => {
    if (!Array.isArray(data) || !key) return null;

    const item = data.find((item) => item?.key_name === key);

    return item ? item.key_value : null;
  }, [data, key]);

  return result;
};

export default useSettingsData;
