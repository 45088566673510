import React from "react";
import "./Header2.css";
import Header2Content from "../header2Content/Header2Content";


const Header2 = () => {
  return (
    <header className=" border-bottom borderrrrrrr">
      <Header2Content />
    </header>
  );
};

export default Header2;
