import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
const handleSubmit = async (e) => {
  e.preventDefault();
  const token = await recaptchaRef.current.executeAsync();
  console.log("Received Token:", token);
};
function Recaptcha() {
return (
 <ReCAPTCHA
  sitekey="6LdZqMgqAAAAALh1ft3SyjAB7EcMky597NA-ETFx"
  ref={recaptchaRef}
/> )
}
export default Recaptcha