import { apiServices } from "../../Shared/baseUrl";
export const userLogin = async (payload) => {
  try {
    const response = await apiServices.post("/login", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserData = async (token) => {
  try {
    const confiq = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiServices.get("/user", confiq);
    return response.data;
  } catch (error) {}
};
export const register = async (payload) => {
  try {
    const response = await apiServices.post("/register", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkOtp = async (payload) => {
  try {
    const response = await apiServices.post("/check-otp", payload);
    return response.data;
  } catch (error) {}
};

export const resendOtp = async (payload) => {
  try {
    const response = await apiServices.post("/resend-otp", payload);
    return response.data;
  } catch (error) {}
};
export const forgetPassword = async (payload) => {
  try {
    const response = await apiServices.post("/forget-password", payload);
    return response.data;
  } catch (error) {}
};


export const subScribe = async (payload) => {
    try {
      const response = await apiServices.post("/subscribe", payload);
      if (response.data?.status_code === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
 export const updateProfile = async (token, payload) => {
    try {
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await apiServices.post("/edit-user-profile", payload, config);
        return response.data;
      } 
      
    } catch (error) {
      return error;
    }
  };
  export const logoutService = async (token) => {
    if (token) {
      try {
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
  
          },
        };
        const response = await apiServices.get('/logout', config);
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error('Token not provided');
    }
  };