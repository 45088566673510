import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_URL = process.env.REACT_APP_IMAGE_URL;
export const SECRET_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const REQUIRED = "Required";
export const apiServices = axios.create({
  baseURL: BASE_URL,
});
export const MAXIMUM_NUMBER_OF_PRODUCTS_IN_COMPARE_LIST = 5;
export const MAXIMUM_NUMBER_OF_PRODUCTS_IN_QUICKVIEW_LIST = 10;
