import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useSendToken from "../../Hooks/useSendToken";

export const fetchUserCartDetails = createAsyncThunk(
  "user/fetchUserCartDetails",
  async (token) => {
    try {
      const apiServie = useSendToken(token);
      const response = await apiServie.get("/user");
      if (response.data.status_code === 200) {
        return response.data.data;
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      localStorage.removeItem("token");
    }
  }
);

const initialState = {
  userDetails: [],
  userDetailsLoding: false,
  userDetailsError: "",
};
const getUserDetails = createSlice({
  name: "userDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCartDetails.pending, (state) => {
        state.userDetailsLoding = true;
      })
      .addCase(fetchUserCartDetails.fulfilled, (state, action) => {
        state.userDetailsLoding = false;
        state.userDetails = action.payload;
      })
      .addCase(fetchUserCartDetails.rejected, (state) => {
        state.userDetailsLoding = false;
        state.userDetailsError = "redux check";
      });
  },
});

export default getUserDetails.reducer;
