import { useNavigate } from "react-router-dom";
const useNavigateMenuId = () => {
  const navigate = useNavigate();
  const navigatePageId = ( slugData) => {
    if (slugData) {
      navigate(`/${slugData?.page_slug}`, {
        state: {
          page_id: slugData?.page_id,
        },
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return { navigatePageId };
};
export default useNavigateMenuId;
