import './addToCartBtn.css'

function AddToCartBtn({children,type}) {
  return (
    <button className={`add_btn ${type === "primary" ? "primary" :'secondary'}`} type='submit'>
      {children}
    </button>
  )
}

export default AddToCartBtn