import React from "react";
import "./Header1.css";
import useReducerHook from "../../../Hooks/useReducerHook";
import useSettingsData from "../../../Hooks/useSettingsData";
import useNavigateMenuId from "../../../Hooks/useMenuId";
import useFindMenuID from "../../../Hooks/useFindSlug";

const Header1 = () => {
  const { settingsData, pageMenus } = useReducerHook();
  const { navigatePageId } = useNavigateMenuId();
  let registerPage = useFindMenuID(pageMenus?.menuData, "register");
  let aboutPage = useFindMenuID(pageMenus?.menuData, "about");

  const phoneNumber = useSettingsData(settingsData?.settingsData, "PHONE_ONE");
  const phoneNumberTwo = useSettingsData(
    settingsData?.settingsData,
    "PHONE_TWO"
  );
  const siteContent = useSettingsData(
    settingsData?.settingsData,
    "HEADER_ANNOUNCEMENT"
  );
  const token = localStorage.getItem("token");
  return (
    <header className="container-fluid  d-none d-lg-inline-block topHeader">
      <div className="row align-items-center">
        <div className="col-12 col-md-4 col-lg-3 text-center text-md-start header1-first-section-container">
          <ul className="header1-first-section d-flex justify-content-center justify-content-md-center">
            <li onClick={() => navigatePageId(aboutPage)}>
              <a className="font-sm pointer">About Us</a>
            </li>
            {!token && (
              <li onClick={() => navigatePageId(registerPage)}>
                <a className="font-sm pointer">Register</a>
              </li>
            )}
          </ul>
        </div>

        <div className="col-12 col-md-4 col-lg-5 text-center my-2 my-md-0 header1-second-section-container">
          <span className="font-sm color-brand-3">{siteContent}</span>
        </div>
        <div className="col-12 col-md-4 col-lg-4 text-end text-md-center header1-thired-section-container">
          <span className="font-sm color-brand-3">
            Need help? Call Us: {phoneNumber ? phoneNumber : phoneNumberTwo}
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header1;
