import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentBlogFilter: 0,
  blogFilter: 0,
  blogTageFilter: 0,
  mobilerFilter:false
};
export const BlogFilterSlice = createSlice({
  name: "FilterProduct",
  initialState,
  reducers: {
    setBlogsCategoryId: (state, action) => {
      state.blogFilter = action.payload;
    },
    setBlogsCategoryIdEmty: (state) => {
      state.blogFilter = 0;
    },
    setBlogsTagId: (state, action) => {
      state.blogTageFilter = action.payload;
    },
    setBlogsTagIdEmty: (state) => {
      state.blogTageFilter = 0;
    },
    setRecentBlogsId: (state, action) => {
      state.recentBlogFilter = action.payload;
    },
    setRecentBlogsIdEmty: (state) => {
      state.recentBlogFilter = 0;
    },
    setMobileFilter: (state,action) => {
      state.mobilerFilter = action.payload;
    },
  },
});
export const {
  setBlogsCategoryId,
  setBlogsCategoryIdEmty,
  setBlogsTagId,
  setBlogsTagIdEmty,
  setRecentBlogsId,
  setRecentBlogsIdEmty,
  setMobileFilter
} = BlogFilterSlice.actions;

export default BlogFilterSlice.reducer;
