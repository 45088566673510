import axios from "axios";
import { BASE_URL } from "../Shared/baseUrl";

const useSendToken = (token) => {
  const apiServices = axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return apiServices
};

export default useSendToken;
