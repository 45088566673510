import React from "react";
import lazyLoader from "../../Assets/lazyLoader.svg";

const LazyLoading = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <img src={lazyLoader} alt="" draggable={false} />
    </div>
  );
};

export default LazyLoading;
