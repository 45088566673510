import React from "react";
import { Avatar, Drawer } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./MobilerRespoiveSlider.css";
import useReducerHook from "../../../Hooks/useReducerHook";
import useFindMenuID from "../../../Hooks/useFindSlug";
import useNavigateMenuId from "../../../Hooks/useMenuId";
import { setSearchData } from "../../../Store/Slice/FiltersProduct";
import { useDispatch } from "react-redux";

const MobilerRespoiveSlider = ({
  mobileResposiveOpen,
  setMobilerResposive,
  handleLogout,
}) => {
  const { userDetails, productFilter } = useReducerHook();
  const { navigatePageId } = useNavigateMenuId();
  const { pageMenus } = useReducerHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let accountPage = useFindMenuID(pageMenus?.menuData, "account");
  let wishlistPage = useFindMenuID(pageMenus?.menuData, "wish_list");
  let shopPage = useFindMenuID(pageMenus?.menuData, "shop");
  const routeToPage = (pageID) => {
    navigatePageId(pageID);
    setMobilerResposive(false);
  };
  const token = localStorage.getItem("token");
  let registerPage = useFindMenuID(pageMenus?.menuData, "register");
  return (
    <Drawer
      anchor="right"
      open={mobileResposiveOpen}
      onClose={() => setMobilerResposive(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "80%",
          maxWidth: "320px",
        },
      }}
    >
      <div className="p-4 mobile-resposive-container">
        <div className="mobile-log mb-2">
          <Link to="/">
            <img
              className="mb-4"
              src="https://ecom-html.vercel.app/demo/assets/imgs/template/logo.svg"
              alt="Logo"
            />
          </Link>
          <div className="border-bottom"></div>
        </div>

        <div className="col-12 mb-3  ">
          <div className="header2-box-header-search ">
            <form className="form-search" method="post" action="#">
              <div className="header2-box-keysearch">
                <input
                  className="form-control font-xs"
                  type="text"
                  placeholder="Search for categories"
                  value={productFilter?.searchData}
                  onChange={(e) => {
                    dispatch(setSearchData(e?.target?.value));
                    navigate(`/${shopPage?.page_slug}`, {
                      state: { page_id: shopPage?.page_id },
                    });
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        <ul className="menu-list">
          {pageMenus?.headerMenuData?.map((item, index) => (
            <li
              key={index}
              className="menu-item mb-2 pointer"
              onClick={() => {
                setMobilerResposive(false);
                navigate(`/${item?.page_slug}`, {
                  state: { page_id: item?.page_id },
                });
              }}
            >
              <a
                href={item.href}
                className="menu-link color-gray-900"
                style={{ textTransform: "capitalize" }}
              >
                {item?.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="border-bottom"></div>
        <div className="mobile-account my-4">
          <div
            className="d-flex align-items-center gap-2 mb-4"
            onClick={() => {
              routeToPage(accountPage);
            }}
          >
            <div className="">
              <Avatar className="">
                {userDetails.userDetails?.full_name?.charAt(0)}
              </Avatar>
            </div>
            <div className="content">
              <h6 className="user-name">
                Hello
                <span className="text-brand">
                  {" "}
                  {userDetails.userDetails?.full_name} !
                </span>
              </h6>
              {/* <p className="font-xs text-muted">You have 3 new messages</p> */}
            </div>
          </div>
          <ul className="mobile-menu">
            <div className="row">
              {token && (
                <div className="col-6">
                  <div>
                    <span
                      className="text-sm  opacity-75 pointer color-gray-900"
                      onClick={() => routeToPage(accountPage)}
                    >
                      My Account
                    </span>
                  </div>

                  <div>
                    <span
                      className="text-sm opacity-75 pointer color-gray-900"
                      onClick={() => routeToPage(accountPage)}
                    >
                      My Orders
                    </span>
                  </div>
                </div>
              )}
              <div className="col-6">
                {token && (
                  <div>
                    <span
                      className="text-sm  opacity-75 pointer color-gray-900"
                      onClick={() => routeToPage(wishlistPage)}
                    >
                      My Wishlist
                    </span>
                  </div>
                )}
                <div>
                  {token ? (
                    <span
                      className="text-sm color-gray-900 opacity-75 pointer"
                      onClick={handleLogout}
                    >
                      Sign out
                    </span>
                  ) : (
                    <span
                      className="text-sm color-gray-900 opacity-75 pointer"
                      onClick={() => routeToPage(registerPage)}
                    >
                      Sign up
                    </span>
                  )}
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </Drawer>
  );
};

export default MobilerRespoiveSlider;
