import React from "react";
import useReducerHook from "../../../Hooks/useReducerHook";
import useSettingsData from "../../../Hooks/useSettingsData";

const FooterCopyRight = () => {
  const { settingsData } = useReducerHook();
  const siteName = useSettingsData(settingsData?.settingsData, "COPY_RIGHT");
  return (
    <div className="container-fluid">
      <div className="text-center">
        <span>Copyright © {new Date().getFullYear()} {siteName}. All rights reserved.</span>
      </div>
    </div>
  );
};

export default FooterCopyRight;
