import { useSelector } from "react-redux";

const useReducerHook = () => {
  const {
    productData,
    productFilter,
    userCartDetails,
    userDetails,
    blogFilter,
    settingsData,
    pageData,
    mainCategory,
    pageMenus,
  } = useSelector((state) => state);
  return {
    productData,
    productFilter,
    userCartDetails,
    userDetails,
    blogFilter,
    settingsData,
    pageData,
    mainCategory,
    pageMenus,
  };
};

export default useReducerHook;
