import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useSendToken from "../../Hooks/useSendToken";

export const fetchCartDetails = createAsyncThunk(
  "cart/fetchCartDetails",
  async (token, { rejectWithValue }) => {
    try {
      const apiserviceToken = useSendToken(token);
      const response = await apiserviceToken.get("/get-user-cart");
      return response.data;
    } catch (error) {}
  }
);
export const fetchCartTotal = createAsyncThunk(
  "cart/fetchCartTotal",
  async (token, { rejectWithValue }) => {
    try {
      const apiserviceToken = useSendToken(token);
      const response = await apiserviceToken.get("/get-cart-total");
      return response.data;
    } catch (error) {}
  }
);
const initialState = {
  cartDetails: [],
  cardLoding: false,
  cardError: "",
  cartTotal: [],
  cartTotalLoding: false,
  cartTotalError: "",
};

const userCartFetchSlice = createSlice({
  name: "cartFetch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartDetails.pending, (state) => {
        state.cardLoding = true;
      })
      .addCase(fetchCartDetails.fulfilled, (state, action) => {
        state.cardLoding = false;
        state.cartDetails = action.payload;
      })
      .addCase(fetchCartDetails.rejected, (state) => {
        state.cardLoding = false;
        state.cardError = "redux check";
      })
      .addCase(fetchCartTotal.pending, (state) => {
        state.cartTotalLoding = true;
      })
      .addCase(fetchCartTotal.fulfilled, (state, action) => {
        state.cartTotalLoding = false;
        state.cartTotal = action.payload;
      })
      .addCase(fetchCartTotal.rejected, (state) => {
        state.cartTotalLoding = false;
        state.cartTotalError = "redux check";
      });
  },
});
export default userCartFetchSlice.reducer;
