import React from "react";
import "./Error404.css";
import img404 from '../../Assets/404.png'
import back from '../../Assets/back.svg'
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate =useNavigate();
  return (
    <section className="error-404-section-box">
      <div className="container">
        <div className="text-center mb-150 mt-50">
          <div className="image-404 margin-bottom-50">
            <img
              src={img404}
              alt="Ecom"
              className="img-fluid"
            />
          </div>
          <h3 className="error-title">404 - Page Not Found</h3>
          <p className="font-md-bold color-gray-600 error-message">
            Looks like, page doesn't exist
          </p>
          <div className="mt-3">
            <a className="btnq btnq-buy w-auto arrow-back d-inline-flex align-items-center justify-content-center pointer" onClick={()=>navigate("/")}>
              <span className="me-1">
                <img
                  src={back}
                  alt=""
                  className="back-icon"
                />
              </span>
              Back to Homepage
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error404;