import React, { Fragment, useEffect, useState } from "react";
import "./Footer.css";
import FooterCopyRight from "./FooterCopyRight/FooterCopyRight";
import useReducerHook from "../../Hooks/useReducerHook";
import useSettingsData from "../../Hooks/useSettingsData";
import { APP_URL } from "../../Shared/baseUrl";
import { getSubPageData } from "../../Services/Footer/FooterService";
import { useNavigate } from "react-router-dom";
import useNavigateMenuId from "../../Hooks/useMenuId";
import useFindMenuID from "../../Hooks/useFindSlug";

const Footer = () => {
  const navigate = useNavigate();
  const { settingsData, pageMenus } = useReducerHook();
  const { navigatePageId } = useNavigateMenuId();

  const [subPageData, setSupageData] = useState([]);
  const phoneNumber = useSettingsData(settingsData?.settingsData, "PHONE_ONE");
  const phoneNumberTwo = useSettingsData(
    settingsData?.settingsData,
    "PHONE_TWO"
  );
  const addressOne = useSettingsData(settingsData?.settingsData, "ADDRESS_ONE");
  const addressTwo = useSettingsData(settingsData?.settingsData, "ADDRESS_TWO");
  const emailOne = useSettingsData(settingsData?.settingsData, "EMAIL_ONE");
  const emailTwo = useSettingsData(settingsData?.settingsData, "EMAIL_TWO");
  const siteLogo = useSettingsData(settingsData?.settingsData, "SITE_LOGO");
  useEffect(() => {
    getSubPage();
  }, []);

  const getSubPage = async () => {
    try {
      const response = await getSubPageData();
      if (response.status_code === 200) {
        setSupageData(response.data);
      }
    } catch (error) {}
  };

  return (
    <>
      <footer className="container-fluid footer-1 mt-5 ">
        <div className="container p-0">
          <div className="row footer">
            <div className="col-lg-3 width-25">
              <h4 className="mb-3 color-gray-1000">Contact</h4>
              <div className="font-md mb-3 color-gray-900">
                <strong className="font-md-bold">Address:</strong>
                {addressOne && addressTwo ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${addressOne} <br /> ${addressTwo}`,
                    }}
                  ></span>
                ) : addressOne ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: addressOne,
                    }}
                  ></span>
                ) : (
                  addressTwo
                )}
              </div>

              <div className="font-md mb-3 color-gray-900">
                <strong className="font-md-bold">Phone:</strong>{" "}
                {phoneNumber && phoneNumberTwo
                  ? `${phoneNumber}, ${phoneNumberTwo}`
                  : phoneNumber ||
                    phoneNumberTwo ||
                    "No phone number available"}
              </div>
              <div className="font-md mb-3 color-gray-900">
                <strong className="font-md-bold">E-mail:</strong>{" "}
                {emailOne && emailTwo
                  ? `${emailOne}, ${emailTwo}`
                  : emailOne || emailTwo || "No Email available"}
              </div>
            </div>
            <div className="col-lg-4 width-25">
              <h4 className="mb-3 color-gray-1000">Make Money with Us</h4>
              <ul className="menu-footer">
                {subPageData?.map((item) => (
                  <li key={item?.page_id} className="pointer">
                    <a
                      onClick={() => {
                        navigate("/sub-page", {
                          state: { subPageId: item?.page_id },
                        });
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {item?.page_name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-4  width-25">
              <h4 className="mb-3 color-gray-1000">Company</h4>
              <ul className="menu-footer">
                {pageMenus?.headerMenuData?.map((menu) => (
                  <li
                    key={menu?.page_id}
                    className="pointer"
                    onClick={() => navigatePageId(menu)}
                  >
                    <a>{menu?.name}</a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 width-25 ">
              <div className="footer-image-container mt-5">
                <img
                  src={APP_URL + siteLogo}
                  alt=""
                  className="img-fluid pointer"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
      <FooterCopyRight />
    </>
  );
};

export default Footer;
