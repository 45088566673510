import axios from "axios";
import React, { useState } from "react";
import Recaptcha from "./Recaptcha";

const Captcha = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    password: "",
    confirmPassword: "",
    terms_agree: false,
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let newErrors = {};
    if (!formData.full_name.trim()) {
      newErrors.full_name = "Full Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phone_no) {
      newErrors.phone_no = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phone_no)) {
      newErrors.phone_no = "Phone Number must be 10 digits";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!formData.terms_agree) {
      newErrors.terms_agree = "You must agree to the terms and conditions";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        ...formData,
        phone_no: +formData.phone_no,
        terms_agree: formData?.terms_agree ? 1 : 0,
      };
      try {
        const response = await axios.post("/register", payload);
        setFormData({
          full_name: "",
          email: "",
          phone_no: "",
          password: "",
          confirmPassword: "",
          terms_agree: false,
        });
        if (response.status_code === 200) {
          alert("form submited successfully!");
          setFormData({});
        }
      } catch (error) {
        
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-3">Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Full Name</label>
          <input
            type="text"
            className="form-control"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
          />
          {errors.full_name && (
            <small className="text-danger">{errors.full_name}</small>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <small className="text-danger">{errors.email}</small>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Phone Number</label>
          <input
            type="tel"
            className="form-control"
            name="phone_no"
            value={formData.phone_no}
            onChange={handleChange}
          />
          {errors.phone_no && (
            <small className="text-danger">{errors.phone_no}</small>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && (
            <small className="text-danger">{errors.password}</small>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && (
            <small className="text-danger">{errors.confirmPassword}</small>
          )}
        </div>
        <Recaptcha/>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="terms_agree"
            checked={formData.terms_agree}
            onChange={handleChange}
          />
          <label className="form-check-label">
            I agree to the terms and conditions
          </label>
          {errors.terms_agree && (
            <small className="text-danger d-block">{errors.terms_agree}</small>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </form>
    </div>
  );
};

export default Captcha;