import React, { useState } from "react";
import "./CatgorySlider.css";
import useReducerHook from "../../../Hooks/useReducerHook";
import { APP_URL } from "../../../Shared/baseUrl";
import useNavigateViewProduct from "../../../Hooks/useNavigateViewProduct";
import useFindMenuID from "../../../Hooks/useFindSlug";

const CatgorySlider = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [hoveredSubItem, setHoveredSubItem] = useState(null);
  const { navigateViewProduct } = useNavigateViewProduct();
  const { pageMenus } = useReducerHook();

  const handleToggleMenu = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const { mainCategory } = useReducerHook();
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleMouseEnter = (item) => {
    setActiveSubMenu(item.id);
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(null);
  };

  const handleSubMenuEnter = (subItem) => {
    setHoveredSubItem(subItem.id);
  };

  const handleSubMenuLeave = () => {
    setHoveredSubItem(null);
  };
  let viewshopID = useFindMenuID(pageMenus?.menuData, "shop");

  const navigateShop = (productDetails) => {
    navigateViewProduct(productDetails, viewshopID);
    setIsMenuVisible(false)
  };
  return (
    <div className="sidebar-left">
      <a
        className="btnop btn-open"
        onClick={handleToggleMenu}
        role="button"
      ></a>

      <ul
        className={`menu-texts ${isMenuVisible ? "menu-open" : "menu-close"}`}
      >
        {mainCategory.mainCatgory?.map((item) => {
          const hasSubCategories = mainCategory.subCatgory.some(
            (subItem) => subItem.parent_id === item.id
          );

          return (
            <li
              className={`has-children ${
                activeSubMenu === item.id ? "submenu-open" : ""
              }`}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={handleMouseLeave}
              key={item.id}
            >
              <a>
                <span className="img-link">
                  <img
                    src={
                      item?.category_image
                        ? APP_URL + item?.category_image
                        : "https://ecom-html.vercel.app/demo/assets/imgs/page/homepage6/armchair.svg"
                    }
                    alt="Ecom"
                    width={24}
                    height={24}
                  />
                </span>
                <span className="text-link" onClick={()=>navigateShop()}>{item?.category_name}</span>
              </a>
              {hasSubCategories && (
                <ul className="sub-menu">
                  {mainCategory.subCatgory
                    .filter((subItem) => subItem.parent_id === item.id)
                    .map((filteredSubCat) => {
                      const hasNestedSubCategories =
                        mainCategory.subCatgory.some(
                          (nestedItem) =>
                            nestedItem.parent_id === filteredSubCat.id
                        );

                      return (
                        <li
                          key={filteredSubCat.id}
                          onMouseEnter={() =>
                            handleSubMenuEnter(filteredSubCat)
                          }
                          onMouseLeave={handleSubMenuLeave}
                          onClick={()=>navigateShop({product_id: filteredSubCat?.id})}
                        >
                          <a>{filteredSubCat?.category_name}</a>

                          {hasNestedSubCategories &&
                            hoveredSubItem === filteredSubCat.id && (
                              <ul className="sublist-menu">
                                {mainCategory.subCatgory
                                  .filter(
                                    (nestItem) =>
                                      nestItem?.parent_id === filteredSubCat.id
                                  )
                                  .map((nestedSubCat) => (
                                    <li key={nestedSubCat.id}    onClick={()=>navigateShop({product_id: nestedSubCat?.id})}>
                                      <a>{nestedSubCat?.category_name}</a>
                                    </li>
                                  ))}
                              </ul>
                            )}
                            {hasNestedSubCategories &&
                            hoveredSubItem === filteredSubCat.id && (
                              <ul className="sublist-menu-res">
                                {mainCategory.subCatgory
                                  .filter(
                                    (nestItem) =>
                                      nestItem?.parent_id === filteredSubCat.id
                                  )
                                  .map((nestedSubCat) => (
                                    <li key={nestedSubCat.id} onClick={()=>navigateShop({product_id: nestedSubCat?.id})}>
                                      <a>{nestedSubCat?.category_name}</a>
                                    </li>
                                  ))}
                              </ul>
                            )}
                        </li>
                      );
                    })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CatgorySlider;
