import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getSubPageData = async () => {
  try {
    const response = await apiService.get("/get-sub-pages");
    return response.data;
  } catch (error) {}
};
export const getSupPageDetails = async (subPageId) => {
  try {
    const response = await apiService.get(`/sub-page-detail/${subPageId}`);
    return response.data;
  } catch (error) {}
};
