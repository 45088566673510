import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../Component/Layout/Layout";
// import Home from "../Pages/Home/Home";
// import Shop from "../Pages/Shop/Shop";
// import Cart from "../Pages/Cart/Cart";
// import Contact from "../Pages/Contact/Contact";
// import About from "../Pages/About/About";
import Error404 from "../Component/Error404/Error404";
import LazyLoading from "../Component/LazyLoading/LazyLoading";
import Captcha from "../Component/Test/Captcha";
import Test from "../Pages/Test";
// import Register from "../Pages/Auth/register/Register";
// import Login from "../Pages/Auth/login/Login";
// import Otp from "../Pages/Auth/otp/Otp";
// import ForgetPassword from "../Pages/Auth/forgetPassword/ForgetPassword";
// import ViewProduct from "../Pages/ViewProduct/ViewProduct";
// import Blog from "../Component/Blogs/Blog";
// import CompareProduct from "../Pages/Compare/CompareProduct";
// import Wishlist from "../Component/Wishlist/Wishlist";
// import Checkout from "../Pages/Checkout/Checkout";
// import Faq from "../Pages/Faq/Faq";
// import AllPages from "../Pages/AllPages/AllPages";
// import Profile from "../Pages/profile/Profile";
// import SubPage from "../Pages/SubPage/SubPage";
const isAuthenticated = () => {
  return !!localStorage.getItem("token");
};
// Wrapper for protected routes
const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};
const Otp = lazy(() => import("../Pages/Auth/otp/Otp"));
const ForgetPassword = lazy(() =>
  import("../Pages/Auth/forgetPassword/ForgetPassword")
);
const Checkout = lazy(() => import("../Pages/Checkout/Checkout"));
const AllPages = lazy(() => import("../Pages/AllPages/AllPages"));
const SubPage = lazy(() => import("../Pages/SubPage/SubPage"));
const routes = [
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/home" />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "/otp-verfiy",
        element: <Otp />,
      },
      {
        path: "/forger-password",
        element: <ForgetPassword />,
      },
      {
        path: "/sub-page",
        element: <SubPage />,
      },
      {
        path: "/checkout",
        element: (
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        ),
      },
      {
        path: "/:slug",
        element: <AllPages />,
      },
      {
        path: "/captcha",
        element: <Captcha/>,
      },
      
    ],
  },
  {
    path: "/test",
    element: <Test/>,
  }
];
const AllRoutes = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
          </Route>
        ))}
      </Routes>
    </Suspense>
  );
};
export default AllRoutes;
