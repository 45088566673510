import React, { useEffect, useState } from "react";
import "./Header2Content.css";
import { IoIosMenu } from "react-icons/io";
import MobilerRespoiveSlider from "../MobilerRespoiveSlider/MobilerRespoiveSlider";
import { APP_URL } from "../../../Shared/baseUrl";
import useSettingsData from "../../../Hooks/useSettingsData";
import useReducerHook from "../../../Hooks/useReducerHook";
import { useDispatch } from "react-redux";
import { setSearchData } from "../../../Store/Slice/FiltersProduct";
import { useLocation, useNavigate } from "react-router-dom";
import useFindMenuID from "../../../Hooks/useFindSlug";
import { logoutService } from "../../../Services/Auth/AuthService";
import accountImage from "../../../Assets/account.svg";
import wishlistImage from "../../../Assets/wishlist.svg";
import cartImage from "../../../Assets/cart.svg";
import compareImage from "../../../Assets/compare.svg";
import toast from "react-hot-toast";
import AddToCartBtn from "../../AddToCartBtn/AddToCartBtn";
import { setProfileMenuID } from "../../../Store/Slice/ProfileMenuSlice";
import { TAB_1, TAB_2, TAB_3, TAB_4, TAB_5, TAB_6 } from "../../../Shared/tabs";
import { trimContent } from "../../Common/trimContent";
import {
  fetchCartDetails,
  fetchCartTotal,
} from "../../../Store/Slice/CartSlice";
import { MdCancel } from "react-icons/md";
import { removeCartData } from "../../../Services/Cart/CartServices";
import { fetchUserCartDetails } from "../../../Store/Slice/UserDetails";

const accountData = [
  { id: 1, name: " My Account", api_flag: TAB_1 },
  { id: 2, name: " My Wishlist", api_flag: TAB_2 },
  { id: 3, name: " My Orders", api_flag: TAB_3 },
  { id: 4, name: " Address Book", api_flag: TAB_5 },
  { id: 5, name: " Change Password ", api_flag: TAB_6 },
  { id: 6, name: "Sign out ", api_flag: "logout" },
];
const Header2Content = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [mobileResposiveOpen, setMobilerResposive] = useState(false);
  const { pageMenus, userDetails, productFilter } = useReducerHook();
  const navigate = useNavigate();
  const [isOpen, setIsopen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedPageSlug, setSelectedPageSlug] = useState("home");

  const { settingsData } = useReducerHook();
  const location = useLocation();
  const siteLogo = useSettingsData(settingsData?.settingsData, "SITE_LOGO");
  let accountPage = useFindMenuID(pageMenus?.menuData, "account");
  let wishlistPage = useFindMenuID(pageMenus?.menuData, "wish_list");
  let cartPage = useFindMenuID(pageMenus?.menuData, "cart");
  let comparePage = useFindMenuID(pageMenus?.menuData, "compare");
  let shopPage = useFindMenuID(pageMenus?.menuData, "shop");
  let viewproductID = useFindMenuID(pageMenus?.menuData, "product_details");

  const handleLogout = async () => {
    try {
      if (token) {
        const logoutResponse = await logoutService(token);
        if (logoutResponse.status_code === 200) {
          localStorage.removeItem("token");
          // localStorage.removeItem("product_ids")
          navigate("/");
          toast.success("User logging out");
          setMobilerResposive(false);
        }
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  const handleNavigateProfileDashboard = (menuId) => {
    if (menuId === "logout") {
      return handleLogout();
    } else {
      dispatch(setProfileMenuID(menuId));
      navigate(`/${accountPage?.page_slug}`, {
        state: { page_id: accountPage?.page_id },
      });
    }
  };
  const { userCartDetails } = useReducerHook();
  useEffect(() => {
    if (isCartOpen && token) {
      dispatch(fetchCartDetails(token));
      dispatch(fetchCartTotal(token));
    }
  }, [isCartOpen, token]);

  const handleRemoveProduct = async (id) => {
    try {
      const payload = {
        product_id: id,
      };
      const response = await removeCartData(token, payload);
      if (response.status_code === 200) {
        dispatch(fetchUserCartDetails(token))
        dispatch(fetchCartDetails(token));
        dispatch(fetchCartTotal(token));
      }
    } catch (error) {}
  };
  return (
    <div className="header-2-container">
      <div className="row align-items-center">
        <div className=" col-xl-1 col-lg-2  col-md-2 col-sm-6 col-6">
          <div className="header2-image-container">
            <img
              src={APP_URL + siteLogo}
              alt="Logo"
              width={111}
              height={70}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="col-6 col-xl-3 col-md-5  col-lg-5 d-none d-md-block  ">
          <div className="header2-box-header-search">
            <form className="form-search" method="post" action="#">
              <div className="header2-box-keysearch">
                <input
                  className="form-control font-xs"
                  type="text"
                  placeholder="Search for categories"
                  value={productFilter?.searchData}
                  onChange={(e) => {
                    dispatch(setSearchData(e?.target?.value));
                    navigate(`/${shopPage?.page_slug}`, {
                      state: { page_id: shopPage?.page_id },
                    });
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="col-xl-5 d-none d-xl-block">
          <ul className="header2-thired-section">
            {pageMenus?.headerMenuData?.map((item, index) => (
              <li key={item?.page_id} className= {`pointer ${ `/${item?.page_slug}` ===  location?.pathname ? 'fw-bold':''}`} >
                <span
                  onClick={() =>{
                    navigate(`/${item?.page_slug}`, {
                      state: { page_id: item?.page_id },
                    })
                    setSelectedPageSlug(item?.page_slug)
                  }
                  
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {item?.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-6 col-xl-3 col-lg-5 col-md-5  col-sm-6 ss  d-flex justify-content-lg-end justify-content-md-end justify-content-between ">
          <div
            className="header2-last-myaccount icon-list"
            onMouseEnter={() => setIsopen(true)}
            onMouseLeave={() => setIsopen(false)}
          >
            <span
              className="font-lg items-mysssss account"
              onClick={() =>
                navigate(`/${accountPage?.page_slug}`, {
                  state: { page_id: accountPage?.page_id },
                })
              }
            >
              <div className="icon-wrapper icon-text">
                <img src={accountImage} alt="Account" className="icon-item" />
              </div>
              <span className="name">
                {token
                  ? trimContent(userDetails?.userDetails?.full_name, 1)
                  : "Account"}
              </span>
            </span>
            <div className={`acc-open ${isOpen && token ? "acc-active" : ""}`}>
              <ul>
                {accountData?.length > 0 &&
                  accountData?.map((x) => {
                    return (
                      <li
                        key={x?.id}
                        onClick={() =>
                          handleNavigateProfileDashboard(x?.api_flag)
                        }
                      >
                        {x?.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="font-lg icon-list my-wishlist ">
            <span
              className="font-lg items-mysssss"
              onClick={() =>
                navigate(`/${wishlistPage?.page_slug}`, {
                  state: { page_id: wishlistPage?.page_id },
                })
              }
            >
              <div className="icon-wrapper">
                <img src={wishlistImage} alt="Wishlist" className="icon-item" />
              </div>
              <span className="name">Wishlist</span>
            </span>
          </div>
          <div
            className="d-inline-block icon-list  box-dropdown-cart "
            onMouseEnter={() => setIsCartOpen(true)}
            onMouseLeave={() => setIsCartOpen(false)}
          >
            <span
              className="font-lg items-mysssss"
              onClick={() =>
                navigate(`/${cartPage?.page_slug}`, {
                  state: { page_id: cartPage?.page_id },
                })
              }
            >
              <div className="icon-wrapper">
                <img src={cartImage} alt="Cart" className="icon-item" />
                {token && (
                  <span className=" font-xs number-item">
                    {userDetails?.userDetails?.cart_count}
                  </span>
                )}
              </div>
              <span className="name">Cart</span>
            </span>
            <div
              className={`cart-dropdown_icons ${
                isCartOpen &&
                token &&
                userCartDetails?.cartDetails?.data?.length > 0
                  ? "cart-dropdown_icons-active"
                  : ""
              }`}
            >
              <ul className="card-dropdown-content-hover">
                {userCartDetails?.cartDetails?.data?.map((item) => (
                  <li className="d-flex p-0 justify-content-between flex-wrap border-bottom" key={item?.product_id} >
                    <div className="col-lg-2">
                      <div className="cart-dropdown_icons-img-container" >
                        <img
                          src={APP_URL + item?.thumnail_image}
                          alt=""
                          // width={50}
                          // height={50}
                        />
                      </div>
                      
                    </div>
                    <div className="col-lg-8">
                      <p className="cart-dropdown_para">
                        {trimContent(item?.product_name, 10)}
                      </p>
                      <p className="cart-dropdown_rate">
                        {item?.item_count} X Rs.
                        {item?.original_price?.toFixed(2)}
                      </p>
                    </div>
                    <div className="col-lg-1">
                      <span onClick={()=>handleRemoveProduct(item?.product_id)}> <MdCancel /></span>
                      
                    </div>
                  </li>
                ))}
              </ul>
              <div className="cart-down-total-container">
                <div className="cart-down-total mb-1">
                  <h5>Total</h5>
                  <h5 className="cart-down-total-container-amount">
                    Rs.
                    {userCartDetails?.cartTotal?.data?.total_price.toFixed(2)}
                  </h5>
                </div>
                <div
                  className="cart-down-btn-container"
                  onClick={() =>
                    navigate(`/${cartPage?.page_slug}`, {
                      state: { page_id: cartPage?.page_id },
                    })
                  }
                >
                  <AddToCartBtn type="secondary">View cart</AddToCartBtn>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div className="font-lg my-compare icon-list ">
            <span
              className=" items-mysssss"
              onClick={() =>
                navigate(`/${comparePage?.page_slug}`, {
                  state: { page_id: comparePage?.page_id },
                })
              }
            >
              <div className="icon-wrapper">
                <img src={compareImage} alt="Compare" className="icon-item" />
              </div>
              <span className="name">Compare</span>
            </span>
          </div>
          <div className="font-lg my-menu icon-list d-lg-none ">
            <span className=" items-mysssss">
              <div className="icon-wrapper">
                <IoIosMenu
                  fontSize={24}
                  onClick={() => setMobilerResposive(true)}
                />
              </div>
            </span>
          </div>
          <>
            <MobilerRespoiveSlider
              mobileResposiveOpen={mobileResposiveOpen}
              setMobilerResposive={setMobilerResposive}
              handleLogout={handleLogout}
            />
          </>
        </div>
      </div>
    </div>
  );
};
export default Header2Content;