import { configureStore } from "@reduxjs/toolkit";
import ProductDataReducer from "./Slice/ProductSlice";
import productFilterReducer from "./Slice/FiltersProduct";
import userCartReducer from "./Slice/CartSlice";
import userDetailsReducer from "./Slice/UserDetails";
import BlogFilterReducer from "./Slice/BlogFilterSlice";
import SettingsDataReducer from "./Slice/SettingsDataSlice";
import pageDataReducer from "./Slice/SectionSlice";
import mainCategoryReducer from './Slice/HeaderSlice'
import pageMenuDatasReducer from './Slice/PageMenu';
import profileMenuReducer from "./Slice/ProfileMenuSlice"

const store = configureStore({
  reducer: {
    productData: ProductDataReducer,
    productFilter: productFilterReducer,
    userCartDetails: userCartReducer,
    userDetails: userDetailsReducer,
    blogFilter: BlogFilterReducer,
    settingsData: SettingsDataReducer,
    pageData: pageDataReducer,
    mainCategory:mainCategoryReducer,
    pageMenus:pageMenuDatasReducer,
    profileMenu : profileMenuReducer

  },
});
export default store;
