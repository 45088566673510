import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ProdutsDataList: [],
  isLoading: false,
  error: "",
};

const ProductDataSlice = createSlice({
  name: "productsDetails",
  initialState,
  reducers: {
    setProductData: (state, action) => {
      state.ProdutsDataList = action.payload;
    },
  },
});
export const { setProductData } = ProductDataSlice.actions;
export default ProductDataSlice.reducer;
