import { apiServices } from "../../Shared/baseUrl";

export const pageResponse = async (pageId) => {
  try {
    const response = await apiServices.get(`/get-page-data/${pageId}`);
    const data = response.data?.data;
    if (!data) {
      console.error("API response is empty or null");
      return;
    }

    const groupedData = data.reduce((acc, section) => {
      const { section_name, section_group, place_order } = section;

      if (!acc[place_order]) {
        acc[place_order] = {};
      }

      if (!acc[place_order][section_name]) {
        acc[place_order][section_name] = {};
      }

      if (!acc[place_order][section_name][section_group]) {
        acc[place_order][section_name][section_group] = [];
      }

      acc[place_order][section_name][section_group].push(section);
      return acc;
    }, {});
    return groupedData;
  } catch (error) {}
};
