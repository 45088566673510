import "./header.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSettingsData } from "../../Store/Slice/SettingsDataSlice";
import useReducerHook from "../../Hooks/useReducerHook";
import Header1 from "./Header1/Header1";
import Header2 from "./Header2/Header2";
import CatgorySlider from "./CatgorySlider/CatgorySlider";
import {
  fetchMainDetails,
  fetchSubDetails,
} from "../../Store/Slice/HeaderSlice";
import {
  fetchHeaderMenusData,
  fetchMenuData,
} from "../../Store/Slice/PageMenu";
import { fetchUserCartDetails } from "../../Store/Slice/UserDetails";

function Header() {
  const token = localStorage.getItem("token");
  const [isSticky, setIsSticky] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(fetchUserCartDetails(token));
    }
    dispatch(fetchMenuData());
    dispatch(fetchSettingsData());
    dispatch(fetchMainDetails());
    dispatch(fetchSubDetails());
    dispatch(fetchHeaderMenusData());
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  return (
    <>
      {/* <div className="container-fluid"> */}
        <Header1 />
        <div className={`sticky-bar ${isSticky ? "stick" : ""}`}>
          <Header2 />
        </div>
        <CatgorySlider />
    {/* </div> */}
    </>
  );
}

export default Header;
